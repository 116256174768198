<template>
	<div class="page">
		<div class="title">
			<p>Cookie</p>
			<p class="yellowLine"></p>
		</div>
		<div class="content">
			<div class="text textEN" v-if="lang!=6">
				<p class="b">Cookies</p>
				<p>This website uses the so-called "Cookie". Cookies are small text files stored on the user's local
					computer. If visit a website, the website will use Cookie to identify the user. In this way, PAIG
					(China) can improve the user-friendliness of the website and adjust the website according to users'
					needs. The website Cookie stored on the user's computer will remain valid indefinitely after the
					user first visit to the website.</p>
				<p>Users can change the settings of his/her web browser to notify him/her when a Cookie is stored on
					his/her computer. Users can also change the settings so that the browser does not accept Cookie from
					this website. However, if the user's browser does not accept Cookie from this website, the user will
					not be able to access or use all the features of this website.</p>
				<p class="b">Security and Retention Period</p>
				<p>When transmitting, storing and processing personal data collected through this website, we will adopt
					currently commonly used technical measures to ensure the safety of the data.</p>
				<p>In general, the period of time that PAIG (China) retains personal data collected through this website
					will not exceed two years.</p>
				<p class="b">Access, Correction and Deletion</p>
				<p>At any time, any user can request that PAIG (China) provide him/her with access to the personal data
					it collects about the user. Users can also request PAIG (China) to correct or delete these personal
					data.</p>
			</div>
			<div class="text" v-if="lang==6">
				<p class="b">Cookie</p>
				<p>本网站使用所谓的“Cookie”。Cookie 是存储在您的本地计算机上的一些小型文本文件。如果您访问某个网站，网站会使用 Cookie
					识别您的身份。这样，宝爱捷（中国）就可以改善网站的用户友好程度，并根据您的需求调整网站。存储在您的计算机上的网站 Cookie 将会在您首次访问网站之后无限期保持有效。</p>
				<p>可以将网络浏览器的设置更改为在有 Cookie 存储在您的计算机上时通知您。也可以更改设置，使浏览器不接受本网站的 Cookie。不过，如果您的浏览器不接受本网站的
					Cookie，您将无法访问或使用本网站的所有功能。</p>
				<p class="b">安全和保留期</p>
				<p>在传送、存储和处理通过本网站收集的个人资料时，我们会采用目前常用的技术措施确保资料的安全性。
					通常，宝爱捷（中国）保留通过本网站收集的个人资料的时间不会超过两年。</p>
				<p class="b">访问、更正和删除</p>
				<p>您可以随时请求宝爱捷（中国）为您提供访问其所收集的关于您的个人资料的权限。您也可以请求宝爱捷（中国）更正或删除这些个人资料。</p>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 70px;
		font-size: 18px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 0;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		padding: 0 111px 0;
	}

	.b {
		font-weight: bold;
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	ul {
		margin-left: 15px;
	}

	.text {
		padding: 0px 0px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;
	}

	.textEN {
		.b {
			margin-top: 30px;
			line-height: 46px;
			font-weight: bold;
			font-size: 22px;
		}
	}
</style>